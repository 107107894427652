
    import { Component, Prop, mixins } from 'nuxt-property-decorator';
    import FormMixin from '@/mixins/formMixin';
    import Button from '@/components/blocks/Button.vue';
    import { ValidationObserver, ValidationProvider } from 'vee-validate';
    import { makeid } from '@/components/editor/utils/editor';

    const striptags = require('striptags');

    @Component({
        components: { Button, ValidationObserver, ValidationProvider }
    })
    export default class BaseForm extends mixins(FormMixin) {
        @Prop() isSmallButton?;
        @Prop({ default: false }) centered: boolean;
        @Prop({ default: false }) longInput: boolean;
        @Prop({ default: false }) inputCenter: boolean;
        @Prop({ default: false }) subscribeForm: boolean;

        formInputId = makeid(16);
        triedToSubmit = false;
        striptags = striptags;

        getFieldClass({ fullWidth, type }) {
            return {
                'w-full': fullWidth || type === 'checkbox',
                'd-flex justify-content-center': type === 'checkbox' && this.centered
            };
        }

        getFormClass() {
            if (this.subscribeForm) return 'subscribe-form';
        }
    }
