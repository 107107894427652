
    import { Component, Prop, Vue } from 'nuxt-property-decorator';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';

    export const meta = {
        title: 'Video',
        options: {
            videoId: {
                title: 'ID видео',
                type: AvailableTypes.string,
                default: 'vJ_67lsuK8E'
            }
        },
        group: AvailableGroups.Видео
    };

    @Component
    export default class Video extends Vue {
        @Prop(String) videoId: string;

        get src() {
            return `https://www.youtube.com/embed/${this.videoId}`;
        }
    }
