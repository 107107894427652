
    import { Component, Prop, Vue } from 'nuxt-property-decorator';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import InlineSlider from '@/components/blocks/InlineSlider.vue';
    import { optionsDefault } from '@/components/editor/config/optionsDefault';
    import { AvailableGroups, AvailableTypes } from '@/components/editor/config/types';

    export interface ItemList {
        title: string;
        href: string;
        targetBlank: boolean;
        img: Image[];
        text: string;
    }

    export interface Image {
        src: string;
        title?: string;
        alt?: string;
    }

    export const meta = {
        title: 'Наши работы',
        options: {
            title: {
                type: AvailableTypes.string,
                title: 'Заголовок',
                default: 'Наши работы'
            },
            items: {
                title: 'Работы',
                type: AvailableTypes.array,
                item: {
                    img: { type: AvailableTypes.image, title: 'Картинка' },
                    title: {
                        type: AvailableTypes.string,
                        title: 'Заголовок'
                    },
                    href: {
                        type: AvailableTypes.string,
                        title: 'Ссылка'
                    },
                    targetBlank: {
                        title: 'Открывать ссылку в новом окне',
                        type: AvailableTypes.boolean
                    },
                    text: {
                        type: AvailableTypes.string,
                        title: 'Текст'
                    }
                },
                default: optionsDefault.WorksList
            }
        },
        group: AvailableGroups.Плитка
    };

    @Component({
        components: { InlineSlider, ImageComponent }
    })
    export default class WorksList extends Vue {
        @Prop(String) title;
        @Prop(Array) items: ItemList[];
    }
